import { QuickReplace } from "static/js/app/utils/QuickReplace";
import * as Api from "static/js/app/api/endpoints";
import DOMPurify from "dompurify";
import { FinanceSearchTerms, SearchCriteria, SortTerm, VehicleSearchResult } from "static/js/app/models/__index";
import { HugoModuleLatestVehiclesSliderConfigMode } from "themes/module_latest_vehicles_slider/static/js/latestVehiclesSliderHugoConfig";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import { Page } from "static/js/app/modules/page";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";
import { VehicleStatus, VehicleStatusFromString } from "static/js/app/models/__index";
import { VehicleComparison } from "static/js/app/modules/vehicleComparison";

export default class LatestVehicles {

    public static init(config_: HugoModuleLatestVehiclesSliderConfigMode, searchVehicleType: string, showMonthlyPrice: boolean, financeConfig?: FinanceSearchTerms) {
        const config = HugoModuleLatestVehiclesSliderConfigMode.applyDefaultsForUndefined(config_, showMonthlyPrice);
        
        let searchTerms = [{ fieldName: 'has_images', targetValue: "true" }, { fieldName: 'reserved', targetValue: "false" }]; 
        let vehicleStatus:VehicleStatus = (searchVehicleType.startsWith("any") ? "all" : searchVehicleType.startsWith("new")? "new":  "used");         
        if (searchVehicleType != null && searchVehicleType != "any")
        {            
            searchTerms.push({ fieldName: 'vehicle_type', targetValue: searchVehicleType.replace(/any|used|new|s|\s/ig, '')});
        }
        if(config.filters != null && config.filters.indexOf("featured") != -1) {
            searchTerms.push({ fieldName: 'featured', targetValue: "true" })
        }

        const sortByOrdered: string[] = (config.sort_by != null) 
            ? config.sort_by.map(k => (k == "latest") ? "source_last_modified" : k)
            : ["featured", "source_last_modified"];

        const sortCriteria = sortByOrdered.map(key => new SortTerm(key, true));
        
        const searchCriteria = new SearchCriteria(searchTerms, null, sortCriteria, 1, config.number_of_items_to_display,null,vehicleStatus,financeConfig);

        Api.Vehicles.search(searchCriteria).then(function (searchResults: VehicleSearchResult) {
            if (searchResults.total > 0 && searchResults.results.some(r => r.main_image != null && r.main_image[config.image_size] != null)) {
                Page.ready(function () {
                    var latestVehiclesTemplate = document.getElementById(config.dom_template_element_id);

                    if (latestVehiclesTemplate) {

                        LatestVehicles.generateLatestVehiclesHtml(searchResults, latestVehiclesTemplate, config);

                        // slick
                        var slickBody = $(`#${config.dom_element_id}`);
                        var slickSettings = JSON.parse(config.slick_settings);
                        slickBody.slick(slickSettings);

                        // show section titles
                        var slickHead = slickBody.siblings("h2");
                        slickHead.removeClass("hide");

                        // show section titles
                        var slickHeadAlt = slickBody.siblings("div .section-title").find("h2").filter(":first");
                        slickHeadAlt.removeClass("hide");

                        // adjust height of items to all be the same as the tallest image
                        if(config.equal_height) {
                          LatestVehicles.equalHeightsLatestVehicles();
                        }
                    }
                });
            }
        });
    }

    private static generateLatestVehiclesHtml(searchResults: VehicleSearchResult, template: HTMLElement, config: HugoModuleLatestVehiclesSliderConfigMode) {
        var items = searchResults.results;

        // Get the contents of the template
        var templateHtml = template.innerHTML;
        // Final HTML variable as empty string
        var listHtml = "";

        // regex creation is reasonably expensive, cache the regex built for repeated templates like this
        var cachedRegEx: RegExp = null;
        var regExCacher = function (regex: RegExp) {
            // return the cachced regex if no regex is supplied, or cache it and return it if it is supplied
            if (regex !== null) {
                cachedRegEx = regex;
            }
            return cachedRegEx;
        };

        // Loop through items, replace placeholder tags
        // with actual data, and generate final HTML
        for (var i = 0; i < items.length; i++) {
            if (items[i].main_image != null && items[i].main_image[config.image_size] !== null) {
                var price: string = (items[i].price > 0)
                    ? '£' + Number(items[i].price).toLocaleString(undefined, { maximumFractionDigits: 0 })
                    : "POA";

                var monthly_payment: string = ((config.show_monthly_payment) && (items[i].monthly_payment > 0)) ? 
                    (items[i].monthly_payment > 0)
                        ? `<div class="l-node-used-vehicle--latest-vehicles__monthly-price"><div class="field field-name-field-vehicle-monthly-price">£${items[i].monthly_payment.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div></div>`
                    : ""
                : "";

                var vatExempt: string = (items[i].vat_exempt == true)
                    ? "<div class='field-name-field-vehicle-vat'>No VAT</div>"
                    : "";

                var vatExcluded: string = (items[i].vat_excluded == true)
                    ? "<div class='field-name-field-vehicle-vat'>Plus VAT</div>"
                    : "";

                    const isSaved = LatestVehicles.checkIfSaved(items[i].id);
                    let saveEmClass = (isSaved) ? "fas" : "far";
                    let saveSectionExtraClasses = (isSaved) ?" saved-button success" : "";
                    let saveText = (isSaved) ? "Saved" : "Save";
                    var save: string = `<section class="compare-button button carlist${saveSectionExtraClasses}" data-vehId="${items[i].id}"><i class="${saveEmClass} fa-star" aria-hidden="true"></i><span> ${saveText}</span></section>`;

                // string replacements to perform
                var replacements = {
                    '%vehicle_id%': items[i].id,
                    '%vehicle_photo%': (items[i].main_image != null) ? items[i].main_image[config.image_size] : "/awaitingimage.jpg",
                    '%vehicle_type%': (items[i].vehicle_type && items[i].vehicle_type.length) ? items[i].vehicle_type.toLowerCase() : '',
                    '%vehicle_type_plural%': (items[i].vehicle_type && items[i].vehicle_type.length) ? items[i].vehicle_type.toLowerCase() + 's' : '',
                    '%vehicle_make%': items[i].manufacturer,
                    '%vehicle_make_display%': items[i].manufacturer_display,
                    '%vehicle_model%': items[i].model,
                    '%vehicle_model_display%': items[i].model_display,
                    '%vehicle_derivative%': StringFormatting.coerceNullishToBlank(items[i].derivative),
                    '%vehicle_derivative_display%': StringFormatting.coerceNullishToBlank(items[i].derivative_display),
                    '%vehicle_price%': price,
                    '%vehicle_monthly_payment%': monthly_payment,
                    '%vehicle_year%': StringFormatting.coerceNullishToBlank(items[i].year_built),
                    '%vehicle_fuel%': StringFormatting.coerceNullishToBlank(items[i].fuel_type_display),
                    '%vehicle_transmission%': StringFormatting.coerceNullishToBlank(items[i].transmission_display),
                    '%vehicle_mileage%': StringFormatting.coerceNullishToBlank(items[i].mileage),
                    '%vehicle_mpg%': StringFormatting.coerceNullishToBlank(items[i].mpg),
                    '%vehicle_vat_exempt%': vatExempt,
                    '%vehicle_vat_excluded%': vatExcluded,
                    "%vehicle_details_url%": items[i].urlPath,
                    '%vehicle_save%': save
                };

                // do replacements (using regex caching)
                listHtml += QuickReplace.quickReplace(replacements, templateHtml, regExCacher);
            }
        }

        // Replace the HTML of #searchResults with final HTML
        document.getElementById(config.dom_element_id).innerHTML = DOMPurify.sanitize(listHtml);
    }

    private static equalHeightsLatestVehicles() {

        var latestVehiclesContainer = $('.l-node-used-vehicle--latest-vehicles__main-content');

        // set a base value
        var maxHeight = -1;
        var maxOuterHeight = -1;

        // Work out the element with the greatest height
        latestVehiclesContainer.each(function () {
            maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
            maxOuterHeight = maxOuterHeight > $(this).outerHeight() ? maxOuterHeight : $(this).outerHeight();
        });

        // Set the height
        latestVehiclesContainer.each(function () {
            $(this).height(maxHeight);
            $(this).css('min-height', maxOuterHeight);
        });
      }

      public static checkIfSaved(vehicleID: string) {
        return VehicleComparison.isVehicleInComparisonList(vehicleID);
      }
    

}
